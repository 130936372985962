<template>
  <div>
    <CContainer fluid class="mainTableContainer">
      <CRow alignVertical="center" alignHorizontal="start" class="mb-4">
        <CCol lg="12" class="mainTableColumn">
          <CButton color="primary" @click="showCreateModal">Create</CButton>
        </CCol>
      </CRow>
      <CRow alignVertical="center" alignHorizontal="center">
        <CCol lg="12" class="mainTableColumn">
          <CDataTable
            :noItemsView="{
              noResults: 'no items found',
              noItems: 'no items',
            }"
            :hover="true"
            :striped="true"
            :items-per-page="20"
            columnFilter
            pagination
            sorter
            :items="slots"
            :clickableRows="true"
            :loading="loading"
            :fields="fields"
            size="sm"
            :tableFilterValue.sync="filter"
          >
            <template #agency_id="{ item }">
              <td>{{ userEmail(item.agency_id) }}</td>
            </template>
            <template #plan_id="{ item }">
              <td>{{ nameTariff(item.plan_id) }}</td>
            </template>
            <template #position="{ item }">
              <td>
                <Pencil :size="20" @click="showEditModal(item)">{{ item.quantity }}</Pencil>
                <Delete :size="20" @click="showDeleteModal(item.id)"></Delete>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CContainer>
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItem"
    />
    <CreateSlotModal
      :show="showCreate"
      :item="createSlot"
      @close="hideCreate"
      @accept="createItem"
      @edit="editItem"
    />
  </div>
</template>

<script>
import { slotModule } from "../store/modules/slotModule";
import { CDataTable, CRow, CCol, CContainer, CButton } from "@coreui/vue/src";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import { TariffEnums } from "@/enums/TariffEnums";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import CreateSlotModal from "@/components/modals/CreateSlotModal.vue";
import {userModule} from "../store/modules/userModule";
export default {
  name: "SlotListComponent",
  data() {
    return {
      showDelete: false,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Agency", key: "agency_id", sorter: true, filter: true },
        { label: "Quantity", key: "quantity", sorter: true, filter: true },
        { label: "Tariff", key: "plan_id", sorter: true, filter: true },
        { label: "Options", key: "position", sorter: false, filter: false },
      ],
      filter: "",
      loading: false,
      deleteItemID: null,
      showCreate: false,
      createSlot: {
        agency_id: null,
        plan_id: 2,
        quantity: 1,
      },
      defaultSlot: {
        agency_id: null,
        plan_id: 2,
        quantity: 1,
      },
    };
  },
  components: {
    CDataTable,
    CCol,
    CRow,
    CContainer,
    Pencil,
    Delete,
    CButton,
    DeleteConfirmModal,
    CreateSlotModal,
  },
  computed: {
    ...slotModule.mapState(["slots"]),
    ...userModule.mapGetters(["getUserListData","getUser"]),
  },
  methods: {
    ...slotModule.mapActions(["getSlots", "putSlot", "postSlot", "deleteSlot"]),
    ...userModule.mapActions(['getUserList']),
    showCreateModal() {
      this.createSlot = JSON.parse(JSON.stringify(this.defaultSlot));
      this.showCreate = true;
    },
    showEditModal(item){
      this.createSlot = JSON.parse(JSON.stringify(item));
      this.showCreate = true;
    },
    async editItem(slot){
      this.showCreate = false;
      await this.putSlot(slot);
      this.createSlot = JSON.parse(JSON.stringify(this.defaultSlot));
    },
    hideCreate() {
      this.showCreate = false;
    },
    async createItem(slot) {
      this.showCreate = false;
      await this.postSlot(slot);
      this.createSlot = JSON.parse(JSON.stringify(this.defaultSlot));
    },
    showDeleteModal(id) {
      this.showDelete = true;
      this.deleteItemID = id;
    },
    hideDelete() {
      this.showDelete = false;
      this.deleteItemID = null;
    },
    async deleteItem() {
      await this.deleteSlot(this.deleteItemID);
      this.deleteItemID = null;
      this.showDelete = false;
    },
    nameTariff(id) {
      if (id === TariffEnums.Free) {
        return "Free";
      } else if (id === TariffEnums.Standart) {
        return "Standart";
      } else if (id === TariffEnums.Profi) {
        return "Profi";
      } else {
        return "Unknown Tariff";
      }
    },
    userEmail(id) {
      let candidate = this.getUserListData.find((x) => x.id === id);
      if (candidate) {
        return candidate.email;
      } else {
        return "";
      }
    },
  },
  async mounted() {
    if(!this.getUserListData.length){
      await this.getUserList();
    };

    await this.getSlots();
  },
};
</script>

<style scoped></style>
