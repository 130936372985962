var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CContainer',{staticClass:"mainTableContainer",attrs:{"fluid":""}},[_c('CRow',{staticClass:"mb-4",attrs:{"alignVertical":"center","alignHorizontal":"start"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.showCreateModal}},[_vm._v("Create")])],1)],1),_c('CRow',{attrs:{"alignVertical":"center","alignHorizontal":"center"}},[_c('CCol',{staticClass:"mainTableColumn",attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"noItemsView":{
            noResults: 'no items found',
            noItems: 'no items',
          },"hover":true,"striped":true,"items-per-page":20,"columnFilter":"","pagination":"","sorter":"","items":_vm.slots,"clickableRows":true,"loading":_vm.loading,"fields":_vm.fields,"size":"sm","tableFilterValue":_vm.filter},on:{"update:tableFilterValue":function($event){_vm.filter=$event},"update:table-filter-value":function($event){_vm.filter=$event}},scopedSlots:_vm._u([{key:"agency_id",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.userEmail(item.agency_id)))])]}},{key:"plan_id",fn:function(ref){
          var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.nameTariff(item.plan_id)))])]}},{key:"position",fn:function(ref){
          var item = ref.item;
return [_c('td',[_c('Pencil',{attrs:{"size":20},on:{"click":function($event){return _vm.showEditModal(item)}}},[_vm._v(_vm._s(item.quantity))]),_c('Delete',{attrs:{"size":20},on:{"click":function($event){return _vm.showDeleteModal(item.id)}}})],1)]}}])})],1)],1)],1),_c('DeleteConfirmModal',{attrs:{"show":_vm.showDelete},on:{"close":_vm.hideDelete,"accept":_vm.deleteItem}}),_c('CreateSlotModal',{attrs:{"show":_vm.showCreate,"item":_vm.createSlot},on:{"close":_vm.hideCreate,"accept":_vm.createItem,"edit":_vm.editItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }