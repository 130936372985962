<template>
    <CModal
            @update:show="closeModal"
            :title="item.id?'Slot Edit':'Slot Create'"
            color="primary"
            :show="show"
            centered
    >
        <CSelect
                label="Tariff"
                :options="selectOptions"
                :value.sync="item.plan_id"
        />
        <CSelect
                label="User"
                :options="userList"
                @update:value="changeUser"
        />
        <CInput label="Count" placeholder="Enter count of slots" v-model="item.quantity" />
    </CModal>
</template>

<script>
  import { CModal, CInput,CSelect } from "@coreui/vue/src";
  import {userModule} from "../../store/modules/userModule";
  export default {
    name: "SlotModal",
    computed:{
      ...userModule.mapGetters(["getUserListData"]),
      userList(){
        return this.getUserListData.reduce((acc,item) => {
          if(item.is_agency){
            const data = {
              value:item.id,
              label:item.name
            };
            acc.push(data);
          }
          return acc;
        },[])
      }
    },
    components:{
      CInput,CModal,CSelect
    },
    props: ["show", "item"],
    data() {
      return {
        selectOptions: [
          { value: 2, label: "Standart" },
          { value: 3, label: "Profi" },
        ],
      };
    },
    methods: {
      ...userModule.mapActions(['getUserList']),
      changeUser(user_id){
        console.log(user_id);
        this.item.agency_id = user_id;
      },
      closeModal(show, event, accept) {
        console.log(this.item);
        if (accept) {
          if(this.item.id){
            this.$emit("edit",this.item);
          }else{
            this.$emit("accept",this.item);
          }
        } else {
          this.$emit("close");
        }
      },
    },
    async mounted() {
      if(!this.getUserListData.length){
        await this.getUserList();
      }
      this.item.agency_id = this.userList[0].value;
    }
  }
</script>

<style scoped>

</style>
